import React from 'react';
import { NavLink } from 'react-router-dom';
import { LayoutDashboard, ClipboardList, Activity, Users, Settings, Siren, Truck } from 'lucide-react';

interface SidebarProps {
  onClose?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose }) => {
  const navItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/' },
    { icon: ClipboardList, label: 'Onboarding', path: '/onboarding' },
    { icon: Activity, label: 'Activity Log', path: '/activity' },
    { icon: Users, label: 'Recruits', path: '/recruits' },
    { icon: Truck, label: 'Scheduling', path: '/apparatus' },
    { icon: Settings, label: 'Settings', path: '/settings' },
  ];

  const handleNavClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="w-64 bg-gray-900 text-white h-full">
      <div className="p-6">
        <div className="flex items-center space-x-3">
          <div className="bg-red-600 p-2 rounded-lg">
            <Siren className="w-8 h-8 text-white" />
          </div>
          <div>
            <h2 className="font-bold">Station 42</h2>
            <p className="text-sm text-gray-400">Admin Portal</p>
          </div>
        </div>
      </div>
      <nav className="mt-6">
        {navItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            onClick={handleNavClick}
            className={({ isActive }) =>
              `flex items-center px-6 py-3 text-sm ${
                isActive
                  ? 'bg-red-600 text-white'
                  : 'text-gray-300 hover:bg-gray-800'
              }`
            }
          >
            <item.icon className="w-5 h-5 mr-3" />
            {item.label}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;