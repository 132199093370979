import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Siren, UserPlus, Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import OnboardingFlow from './components/OnboardingFlow';
import ActivityLog from './components/ActivityLog';
import RecruitList from './components/RecruitList';
import Settings from './components/Settings';
import NotificationSettings from './components/settings/NotificationSettings';
import EmailTemplates from './components/settings/EmailTemplates';
import SecuritySettings from './components/settings/SecuritySettings';
import AffiliateSettings from './components/settings/AffiliateSettings';
import ApparatusSchedule from './components/ApparatusSchedule';
import Login from './components/Login';
import NewRecruitModal from './components/NewRecruitModal';
import { useAuthStore } from './store/authStore';

const App = () => {
  const { isAuthenticated, user, logout } = useAuthStore();
  const [isNewRecruitModalOpen, setIsNewRecruitModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  if (!isAuthenticated) {
    return <Login />;
  }

  // Scheduler role only has access to the scheduling page
  if (user?.role === 'scheduler') {
    return (
      <BrowserRouter>
        <div className="flex flex-col min-h-screen bg-gray-50">
          <header className="bg-white shadow-sm">
            <div className="flex items-center justify-between px-4 py-4">
              <div className="flex items-center space-x-3">
                <div className="bg-red-600 p-2 rounded-lg">
                  <Siren className="w-6 h-6 text-white" />
                </div>
                <div>
                  <h2 className="font-bold text-sm sm:text-base">Station 42</h2>
                  <p className="text-xs sm:text-sm text-gray-400">Scheduling Portal</p>
                </div>
              </div>
              <button
                onClick={logout}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Sign Out
              </button>
            </div>
          </header>
          <main className="flex-1 p-4">
            <Routes>
              <Route path="/apparatus" element={<ApparatusSchedule />} />
              <Route path="*" element={<Navigate to="/apparatus" replace />} />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    );
  }

  // Admin role has access to everything
  return (
    <BrowserRouter>
      <div className="flex flex-col md:flex-row min-h-screen bg-gray-50">
        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="md:hidden fixed top-4 right-4 z-50 bg-red-600 p-2 rounded-lg text-white"
        >
          {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
        </button>

        {/* Mobile Sidebar */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'tween' }}
              className="fixed inset-0 z-40 md:hidden"
            >
              <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsMobileMenuOpen(false)} />
              <Sidebar onClose={() => setIsMobileMenuOpen(false)} />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Desktop Sidebar */}
        <div className="hidden md:block">
          <Sidebar />
        </div>

        <div className="flex-1">
          <header className="bg-white shadow-sm">
            <div className="flex items-center justify-between px-4 py-4">
              <div className="flex items-center space-x-3">
                <Siren className="h-6 w-6 text-red-600" />
                <h1 className="text-lg font-bold text-gray-900">Ready to Ride</h1>
              </div>
              <div className="flex items-center space-x-2 sm:space-x-4">
                <button
                  onClick={() => setIsNewRecruitModalOpen(true)}
                  className="flex items-center px-3 py-1.5 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <UserPlus className="w-4 h-4 mr-1 sm:mr-2" />
                  <span className="hidden sm:inline">Add New Recruit</span>
                  <span className="sm:hidden">Add</span>
                </button>
                <button
                  onClick={logout}
                  className="text-xs sm:text-sm text-gray-600 hover:text-gray-900"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </header>
          <main className="p-4">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/onboarding/:id" element={<OnboardingFlow />} />
              <Route path="/activity" element={<ActivityLog />} />
              <Route path="/recruits" element={<RecruitList />} />
              <Route path="/apparatus" element={<ApparatusSchedule />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/notifications" element={<NotificationSettings />} />
              <Route path="/settings/email-templates" element={<EmailTemplates />} />
              <Route path="/settings/security" element={<SecuritySettings />} />
              <Route path="/settings/affiliates" element={<AffiliateSettings />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </div>
        <NewRecruitModal
          isOpen={isNewRecruitModalOpen}
          onClose={() => setIsNewRecruitModalOpen(false)}
        />
      </div>
    </BrowserRouter>
  );
};

export default App;